* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Quicksand", sans-serif;
}

.header-container {
  width: 100%;
  background-color: #6fcfe7 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  position: fixed !important;
  padding: 0px !important;
  z-index: 99;
}
.header-logo {
  width: 67px;
}

.head-nav {
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.head-nav:hover {
  color: black;
}

.nav-combine {
  display: flex;
  justify-content: center;
}
.head-nav-2 {
  text-decoration: none !important;
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  color: black !important;
  display: flex;
  gap: 10px !important;
}
.nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none !important;
  color: black;
}

.contact-a {
  color: black !important;
}

.banner-6-combine {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.banner-change {
  display: flex;
  justify-content: start;
  align-items: center;
}
.servicebd-color {
  display: flex;
  align-items: end !important;
  justify-content: center !important;
  gap: 40px;
  color: white !important;
}

.banner {
  background-image: url("../public/assets/background-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.banner-carsoul {
  position: absolute;
  right: 0;
  top: 38%;
}

.banner-combine {
  display: flex;
}

.banner-con {
  width: 650px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media(max-width:768px){
  .banner-con {
    /* width: 650px; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .banner{
    height: auto;
  }
}

h1 {
  font-size: 23px !important;
  font-weight: 600 !important;
  width: 100%;
  display: flex;
  justify-content: start !important;
  color: #000;
  text-align: start !important;
}
p {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #000;
  margin-top: 20px;
  line-height: 25px !important;
  margin-bottom: 0px;
}

.banner-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-left {
  width: 40%;
}
.banner-right {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.img1 {
  width: 380px;
  /* height: 330px; */
}

.img2 {
  width: 200px;
  height: 209px;
}

.banner-1-pre {
  overflow: none !important;
  margin-top: 20px;
  line-height: 25px !important;
}

.animation-1 {
  width: 230px !important;
  background-color: white;
  border-radius: 10px;
  display: flex !important;
  gap: 10px;
}

.img-fluid-1-ani {
  width: 100%;
}
/* Inactive slide styles */
.img-fluid-1,
.img-fluid-2 {
  border: none; /* No border for inactive images */
}

/* .active-slide {
  transform: scaleX(0.8) !important;
} */

/* Active slide styles */
.inactive-slide {
  border: 5px solid #6fcfe7;
  background-color: white !important;
  border-radius: 10px;
  /* transform: scaleX(1); */
  /* height: 280px; */
}

.img-fluid-1 {
  width: 130px !important;
  height: 200px !important;
  background-color: white;
}

.img-fluid-2 {
  width: 50px !important;
  height: 50px !important;
  background-color: white;
}
.ani-side {
  width: 100px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: end;
}

.banner-2 {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-2-div1 {
  position: relative;
  width: 330px !important;
  height: 377px !important;
  background-color: #e2f5fa;
  border-radius: 10px;
}
.banner-2-div {
  position: absolute;
  right: 23%;
  bottom: 25%;
  z-index: 3;
}

.banner-2-div > img {
  width: 295px !important;
}

.banner-2-col-combine {
  width: 220px;
  background-color: #e2f5fa;
  border-radius: 5px;
}

.banner-2-col-row-combine {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px;
}

.banner-2-row-1 {
  gap: 20px;
  justify-content: space-between !important;
}

.banner-2-animation-2 {
  width: 100% !important;
  /* border:2px solid brown; */
}

.banner-2-animation-3 {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  row-gap: 40px !important;
  /* border: 2px solid rgb(43, 255, 0); */
}

.banner-2-txt {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.banner-2-col {
  width: 180px;
}
.banner-2-col > img {
  width: 180px;
}
.banner-3 {
  width: 100%;
  /* height: 300px; */
}
.refferal,
.refferal-img {
  width: 100%;
  /* height: 300px !important; */
  /* position: relative; */
}

.banner-4-col > div > img {
  width: 270px !important;
}

.animation-3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.banner-4-but {
  background-color: #6fcfe7 !important;
  color: white !important;
  padding: 5px 30px !important;
  border: 5px solid white !important;
  border-radius: 10px !important;
}

.banner-5-img {
  width: 550px;
}

.banner-5-txt {
  font-size: 16px;
  font-weight: 600 !important;
}

.banner-5-txt-1 {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.banner-6 {
  width: 100%;
  display: flex !important;
  justify-content: end !important;
}

/* Style inactive dots */
.slick-dots li button:before {
  font-size: 12px; /* Size of the dot */
  color: #ea4c71 !important; /* Color of the inactive dot */
  opacity: 0.8; /* Lower opacity for inactive dots */
  content: "•"; /* Using a bullet symbol for radio button look */
}

/* Style active dot */
.slick-dots li.slick-active button:before {
  background-color: #6fcfe7 !important; /* Color of the active dot */
  opacity: 1; /* Full opacity for the active dot */
  content: "•";
  position: absolute;
  gap: 20px !important;
  width: 30px !important;
  height: 15px !important; /* Using a bullet symbol for radio button look */
  /* font-size: 16px; Larger size for active dot */
  border-radius: 10px !important;
  color: #6fcfe7 !important;
}

.animation-2-combine {
  position: relative !important;
}

.animation-2 {
  width: 460px !important;
  position: relative !important;
}

.blue {
  color: #6fcfe7 !important;
  font-family: "Montez", cursive;
  font-size: 30px !important;
  font-weight: 400 !important;
}

.img-fluid-1-ani {
  width: 100% !important;
}

.banner-icon {
  width: 30px;
}
.banner-7 {
  background-color: #e2f5fa;
  overflow-x: hidden !important;
}

.banner-7-txt {
  display: flex !important ;
  justify-content: center !important;
}

.banner-7-img {
  width: 60px;
  height: auto;
}

.banner-7-combine {
  overflow-x: hidden !important;
  padding: 20px;
  margin-top: 50px !important;
}

.banner-7-content {
  display: flex;
  gap: 20px !important;
}
.banner-7-txt-2 {
  padding: 0px !important;
  margin: 0px !important;
  font-weight: 600 !important;
}
.banner-7-txt-3 {
  color: gray;
}

.rating {
  width: 100px;
  font-size: 12px !important;
}

.banner-7-icon {
  display: flex;
  justify-content: start;
  align-items: end;
}

.banner-7-icon > div {
  width: 30px;
  display: flex;
  padding: 5px;
  justify-content: center !important;
  border-radius: 5px;
  background-color: #6fcfe7;
  color: white !important;
  font-size: 17px !important;
}

.banner-7-col {
  padding: 0px !important;
  display: flex !important;
  justify-content: space-evenly !important;
}

.footer {
  box-shadow: 5px -5px 5px lightgray;
}

.footer-1 {
  width: 180px;
  height: 45px;
  background-color: lightgray;
}

.footer-img {
  width: 24px;
  height: auto;
}
.footer-img-combine {
  display: flex;
  gap: 10px;
}

.footer-txt {
  font-size: 14px !important;
  font-weight: 700 !important ;
  margin-top: 20px;
}

.footer-7-txt-2 {
  font-size: 16px;
  font-weight: 700 !important;
}

.footer-7-txt-3 {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000;
}
.css-5poeop {
  background-color: #6fcfe7 !important;
}


.footer-but {
  background-color: #6fcfe7 !important;
  color: white !important;
  border: none !important;
  padding: 3px 20px !important;
}

.footer-input-combine {
  display: flex;
  gap: 20px;
}

.footer-input {
  width: 250px;
  height: 30px;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-input-outlined:hover {
  border: 1px solid #6fcfe7 !important;
}

/* Servicepage */

/* .service-banner-1 {
  padding: 50px 0px;
} */

.service-img {
  width: 500px;
}

.service-txt-1 {
  color: #6fcfe7;
  font-size: 18px;
  font-weight: 700 !important;
}

.head {
  color: #6fcfe7;
  font-size: 30px !important;
  font-family: "Montez", cursive;
  font-weight: 400 !important;
}
.head-1 {
  text-align: none !important;
  display: flex;
  width: 100%;
  justify-content: start !important;
  font-size: 25px !important;
  font-weight: 400 !important;
  font-family: "Montez", cursive;
}

.service-txt {
  font-size: 25px !important;
  font-weight: 500 !important;
}

.home-txt-1 {
  font-size: 25px !important;
  font-weight: 600 !important;
  text-align: none !important;
  display: flex;
  width: 100%;
  justify-content: start !important;
}

.home-txt-2 {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 25px !important;
}

.service-txt-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.service-but {
  border: none !important;
  background-color: #6fcfe7 !important;
  color: white !important;
  padding: 5px 60px !important;
}

/* .service-row {
  margin-top: 70px !important;
} */

.service-banner-3 {
  width: 550px;
  height: 310px;
}

.service-banner-3 > img {
  width: 100%;
}

.contact-txt {
  font-weight: 600 !important;
  text-align: center !important;
  padding-top: 50px;
}

/* YourComponent.css */

.banner-2 {
  /* border: 2px solid red; */
}

.banner-2-ani {
  display: flex !important;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between !important;
  gap: 60px;
}

/* Big Div */
.big-div {
  background-color: #e2f5fa;
  border-radius: 5px;
  padding: 20px;
  width: 230px; /* Adjust as needed */
  height: 310px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  /* border: 2px solid yellow; */
  align-items: center;
}

.large-image {
  position: relative;
  width: 250px;
  height: 270px;
  /* border: 2px solid yellow; */
}

.large-image img {
  width: 250px !important;
  height: 300px !important;
  position: absolute;
  bottom: 10%;
  right: 10%;
  transition: transform 10s ease-in-out, opacity 10s ease-in-out;
  /* transition: all 6s ease-in-out ease-in-out; */
  /* transition: transform 4s ease-in-out !important; */
}

/* Small Divs */
.small-divs {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* border: 2px solid yellow; */
  gap: 30px;
  width: 100%; /* Adjust based on your layout */
}

.small-divX {
  width: 220px;
  height: 190px;
  background-color: #e2f5fa;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.small-div {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: 8%;
  right: 23%;
  transition: all 1s ease-in 100s ease-in-out;
}
.small-div img {
  width: 200px;
  height: 200px;
}

.banner-2-combine {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;
  align-items: flex-start;
  /* border: 2px solid blue; */
}

.small-div.moving {
  transform: translate(
    100px,
    -100px
  ); /* Adjust these values for the animation */
  opacity: 0;
}

.banner-2-but {
  border: none;
  background-color: white;
  color: #6fcfe7 !important;
}

.img-c {
  width: 100% !important;
}

.banner-1 {
  width: 100%;
  height: 100vh;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .service-banner-1 {
    width: 100% !important;
    padding: 20px 20px !important;
  }
  .service-img {
    width: 100% !important;
  }

  .small-divs {
    flex-direction: column !important;
  }

  .banner-4-content {
    gap: 30px !important;
  }

  .banner-4-col > div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .banner-4-col > div > img {
    width: 100% !important;
  }
  .banner-2-ani {
    width: 100% !important;
    flex-direction: column !important;
  }
  .banner-left {
    width: 100% !important;
  }
  .banner-right {
    width: 100% !important;
    flex-direction: column !important;
  }

  .banner-2 {
    margin-top: 35px !important;
  }

  /* .banner-3 {
    margin-top: 250px !important;
    background-image: url("../public/assets/Group\ 970.png");
    width:100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  } */

  .animation-2 {
    width: 100% !important;
  }

  .nav-4{
    display: flex;
    height: 150px;
    margin-top: 40px !important;
    flex-direction: column;
    justify-content: space-between!important;
    align-items: center !important;
    color:white!important;
  }

  .css-11igqa5-MuiModal-root-MuiDrawer-root .MuiDrawer-paper{
    /* height:300px; */
    width: 300px;
    background-color: #e2f5fa !important;
    border-radius: 0px 10px 10px 0px !important;
  }

  .banner-1 {
    flex-direction: column !important;
  }


  .banner-6{
    /* border:2px solid red; */
  }

  .img1,
  .banner-5-img {
    width: 100% !important;
  }

  .banner-5-row {
    flex-direction: column !important;
  }

  .banner-combine-2 {
    width: 100% !important;
  }
  .contact-banner {
    flex-direction: column !important;
  }

  .service-banner-3 {
    width: 100% !important;
    margin-top: 20px;
    height: 170px !important;
  }
  .service-row {
    flex-direction: column !important;
  }
  .footer-input-combine {
    flex-direction: column !important;
  }
  .banner-con {
    width: 100% !important;
  }
  .banner-6-combine {
    flex-direction: column !important;
  }

  .banner-6-combine {
    gap: 10px !important;
  }

  .servicebd-color {
    background-color: #e2f5fa;
    border-radius: 10px;
    justify-content: center !important;
    align-items: center !important;
  }
  .navbar {
    padding: 0px !important;
  }

  .head-nav {
    color: #6fcfe7 !important;
  }
}
.lg-hide {
  display: none !important;
}
.header-container {
  display: block !important;
}

.head-mobile {
  background-color: #cfe4e9 !important;
}

.nav {
  background-color: #6fcfe7 !important;
  justify-content: space-between;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #6fcfe7 !important;
}
.nav-center {
  color: white;
}

.service-banner-1{
  /* border:2px solid red; */
}

/* .service-row{
  flex-direction: column !important;
} */
.nav-2 {
  margin-right: 100px;
}
.header-margin{
  margin-bottom: 5rem;
}

.nav-center:hover {
  color: #000 !important;
}
.nav-1 {
  /* padding:0px 200px; */
  display: flex;
  gap: 200px;
  justify-content: space-between !important;
  align-items: center;
}

@media (max-width: 768px) {
  .lg-hide {
    display: block !important;
  }
  .header-container {
    display: none !important;
  }
  .sm-hide {
    display: none !important;
  }
  .banner-3 {
    padding-top: 2.5rem;
  }
  .sm-banner {
    display: none;
  }
  .nav {
    display: none !important;
  }
  .nav-3 {
    border: 2px solid red !important;
  }

  .refferal-img{
    display: none;
  }

  .banner {
    padding: 80px 0px;
  }

  .css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    margin: 0px !important;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    justify-content: end !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.7rem !important;
  }
}
@media (min-width: 768px) {
  .collapse:not(.show) {
    display: block !important;
  }
}
.nav-link-with-line {
  position: relative;
  padding-bottom: 8px;
  text-decoration: none;
  display: inline-block;
  width: 90%;
}

.nav-link-with-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: gray; /* Change the color as needed */
}

